import { css, createGlobalStyle } from 'styled-components';
import { media } from './utils';
import { black, white, grey } from './colors';

const maxWidth = 1680;

export const GlobalStyles = createGlobalStyle`
    html,
    body {
        margin: 0;
        padding: 0;
        background: ${black};
        color: ${white};
        font-family: "Suisse Int'l";
        font-size: 30px;
        line-height: 39px;
        letter-spacing: -0.025em;
        -webkit-font-smoothing: antialiased;
    }
    
    a {
        text-decoration: none;
        color: ${white};
    }

    h1, h2, h3 {
        font-size: 1rem;
        font-weight: 300;
        margin: 0;
    }

    a, p, div {
        color: ${white};
    }

    b, strong {
        font-weight: 500;
    }

    /* 
    * {
        -webkit-overflow-scrolling: touch;
    } */

    p:first-child {
		margin-top: 0;
	}

    &::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE and Edge */
    & {
        -ms-overflow-style: none;
    }
`;

export const container = css`
    display: flex;
	width: 100%;
    max-width: ${maxWidth}px;
`

export const hoverState = css`
    transition: 0.15s opacity ease;

    &:hover {
        opacity: 0.7;
    }
`

export const padding = css`
    padding-left: 70px; 
    padding-right: 70px;
    box-sizing: border-box;

    ${media.tablet`
        padding-left: 32px; 
        padding-right: 32px;
    `}
`

export const bgImage = css`
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
`

export const bgIcon = css`
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
`